"use client";

import React, { useEffect } from "react";
import Header from "@/components/Header";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { Box, Divider, styled } from "@mui/material";
import { Analytics } from "@vercel/analytics/react";
import { useDispatch } from "react-redux";
import { gameDataCacheAction } from "@/store/gameDataCache";
import useGetPathKey from "@/hooks/useGetPathKey";
import { hoursToMilliseconds } from "date-fns";
import AppSubscriptions from "./AppSubscriptions";
import WebNotification from "../WebNotification";
import Footer from "../Footer";
import { HEADER_HEIGHT } from "../Header/common";
import HeaderNavPanelMobile from "../Header/HeaderNavPanelMobile";
import GameLiveDataSubCall from "../Common/GameLiveData/GameLiveDataSubCall";
import NavigationPanel from "../NavigationPanel";

const MainWrapper = styled("main")`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const StyledBox = styled(Box)(
  ({ theme }) => `
  width: 100%;
  
  ${theme.breakpoints.up("md")} {
    border-radius: ${theme.spacing(3)};
  }
  ${theme.breakpoints.down("md")} {
    max-width: none;
  }
`,
);

type AppProvidersProps = {
  children: React.ReactNode;
};

function AppLayout(props: AppProvidersProps) {
  const dispatch = useDispatch();
  const { children } = props;

  const pathKey = useGetPathKey();

  useEffect(() => {
    const clearCache = setInterval(() => {
      dispatch(gameDataCacheAction.clearCacheData());
    }, hoursToMilliseconds(2));

    return () => {
      clearInterval(clearCache);
    };
  }, [dispatch]);

  const isHomepage = pathKey === undefined || pathKey.toString() === "welcome";

  return (
    <>
      <Header endComponent={<HeaderNavPanelMobile />} />
      <Divider />
      <MainWrapper>
        {!isHomepage && <NavigationPanel />}
        <StyledBox>
          {children}
          <AppSubscriptions />
          <GameLiveDataSubCall />
          <Analytics />
          <SpeedInsights />
          <WebNotification />
        </StyledBox>
      </MainWrapper>
      <Footer />
    </>
  );
}

export default AppLayout;
