import { useAppSelector } from "@/store/store";
import { Stack, Typography, styled } from "@mui/material";
import React from "react";
import { ServerStatusEnum } from "@/store/toolsSportCounts";
import ServerStatusIcon from "../Icons/ServerStatusIcon";
import ConnectIcon from "../Icons/ConnectIcon";

const getServerStatusIconColor = (serverStatus?: ServerStatusEnum) => {
  if (serverStatus === ServerStatusEnum.ONLINE) {
    return "success";
  }
  if (serverStatus === ServerStatusEnum.MAINTENANCE) {
    return "maintenance";
  }
  if (serverStatus === ServerStatusEnum.DEGRADED) {
    return "warning";
  }
  if (serverStatus === ServerStatusEnum.OFFLINE) {
    return "error";
  }
  return "error";
};
const getServerStatusText = (serverStatus?: ServerStatusEnum) => {
  if (serverStatus === ServerStatusEnum.ONLINE) {
    return "Online";
  }
  if (serverStatus === ServerStatusEnum.MAINTENANCE) {
    return "Maintenance";
  }
  if (serverStatus === ServerStatusEnum.DEGRADED) {
    return "Degraded";
  }
  if (serverStatus === ServerStatusEnum.OFFLINE) {
    return "Offline";
  }
  return "Offline";
};

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  ${theme.breakpoints.down("md")} {
    display: none;
  }
`,
);

function ServerStatus() {
  const serverStatus = useAppSelector((state) => state.toolsSportCountsReducer.serverStatusId);

  if (!serverStatus) return null;

  return (
    <Stack direction="row" alignItems="center" px={1}>
      {serverStatus === ServerStatusEnum.ONLINE ? (
        <ConnectIcon sx={{ fill: "#52694B", fontSize: 32 }} />
      ) : (
        <>
          <ServerStatusIcon color={getServerStatusIconColor(serverStatus)} />
          <StyledTypography variant="body1" ml={1}>
            {getServerStatusText(serverStatus)}
          </StyledTypography>
        </>
      )}
    </Stack>
  );
}

export default ServerStatus;
