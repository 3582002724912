export const BASKETBALL_LIVE_DATA_TYPE_FRAGMENT = `
  fragment BasketballLiveDataTypeFragment on BasketballLiveDataType {
    gameId
    additionalData
    period
    gameTimeSeconds
    homeTeamHasPossession
    # isClockRunning
    # awayTeamFoulsByQuarter
    # homeTeamFoulsByQuarter
    # awayTeamFreeThrowsMissedByQuarter
    # homeTeamFreeThrowsMissedByQuarter
    # awayTeamFreeThrowsPendingByQuarter
    # homeTeamFreeThrowsPendingByQuarter
    # awayTeamFreeThrowsScoredByQuarter
    # homeTeamFreeThrowsScoredByQuarter
    # awayTeamThreePointersByQuarter
    # homeTeamThreePointersByQuarter
    awayTeamTotalPointsByQuarter
    homeTeamTotalPointsByQuarter
    # awayTeamTwoPointersByQuarter
    # homeTeamTwoPointersByQuarter
  }
`;

export type BasketballLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  // isClockRunning: boolean,
  // awayTeamFoulsByQuarter: number[],
  // homeTeamFoulsByQuarter: number[],
  // awayTeamFreeThrowsMissedByQuarter: number[],
  // homeTeamFreeThrowsMissedByQuarter: number[],
  // awayTeamFreeThrowsPendingByQuarter: number[],
  // homeTeamFreeThrowsPendingByQuarter: number[],
  // awayTeamFreeThrowsScoredByQuarter: number[],
  // homeTeamFreeThrowsScoredByQuarter: number[],
  // awayTeamThreePointersByQuarter: number[],
  // homeTeamThreePointersByQuarter: number[],
  awayTeamTotalPointsByQuarter: number[];
  homeTeamTotalPointsByQuarter: number[];
  // awayTeamTwoPointersByQuarter: number[],
  // homeTeamTwoPointersByQuarter: number[],
};
