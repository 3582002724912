import { Box, Divider, IconButton, Stack, styled, Typography } from "@mui/material";
import React, { ComponentProps, useCallback } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslations } from "next-intl";
import { useAppSelector } from "@/store/store";
import { ServerStatusType } from "@/store/toolsSportCounts";
import { PAGES } from "./common";
import NavItem from "./NavItemDesktop";
import CloseRoundedIcon from "../Icons/CloseRoundedIcon";
import Header from "../Header";

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-item: start;
    flex-direction: column; 
    background-color: ${theme.palette.primary.main};
    height: 100%;
  `,
);

type NavigationPanelMobileProps = {
  onClose: () => void;
} & ComponentProps<typeof Box>;

const NavigationPanelMobile = React.forwardRef<HTMLDivElement, NavigationPanelMobileProps>((props, ref) => {
  const { onClose, ...restProps } = props;
  const t = useTranslations("common");

  const counts = useAppSelector((state) => state.toolsSportCountsReducer);

  const getCount = useCallback(
    (countKey: string | undefined) => {
      if (!countKey) return "";
      const selectedCounts = counts[countKey as keyof ServerStatusType] as number[];
      const sum = selectedCounts.reduce((prev, cur) => prev + cur, 0);
      if (sum < 1000) return sum.toString();
      return `${(sum / 1000).toFixed(1)}K`;
    },
    [counts],
  );

  return (
    <StyledBox ref={ref} {...restProps}>
      <Header
        endComponent={
          <IconButton onClick={onClose} size="small" sx={{ px: 1.5, height: 48, width: 48 }}>
            <CloseRoundedIcon sx={{ fontSize: 16 }} />
          </IconButton>
        }
      />
      <Divider />
      <Box px={2} mt={5}>
        {PAGES.map((page) => (
          <NavItem key={page.title} href={page.route} icon={page.icon} onClose={onClose} disableFocusRipple disableRipple>
            <Stack direction="row" justifyContent="space-between" flexGrow={1} alignItems="center">
              {t(page.title)}
              <Typography color="text.secondary" variant="label">
                &nbsp;{getCount(page.countKey)}
              </Typography>
            </Stack>
          </NavItem>
        ))}
        <Divider sx={{ my: 2 }} />
        <NavItem icon={<SettingsIcon />} href="/settings" onClose={onClose}>
          {t("settings")}
        </NavItem>
      </Box>
    </StyledBox>
  );
});

export default NavigationPanelMobile;
