import { IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationPanelMobile from "../NavigationPanel/NavigationPanelMobile";

function HeaderNavPanelMobile() {
  const [openNav, setOpenNav] = useState(false);

  const handleOpenNavMenu = () => {
    setOpenNav(true);
  };

  const handleCloseNavMenu = () => {
    setOpenNav(false);
  };
  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Modal open={Boolean(openNav)} onClose={handleCloseNavMenu}>
        <NavigationPanelMobile onClose={handleCloseNavMenu} />
      </Modal>
    </>
  );
}

export default HeaderNavPanelMobile;
