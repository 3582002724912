export const BASEBALL_LIVE_DATA_TYPE_FRAGMENT = `
  fragment BaseballLiveDataTypeFragment on BaseballLiveDataType {
    gameId
    additionalData
    period
    isHomeTeamBatting
    currentStrikes
    currentBalls
    awayTeamScoreByInning
    homeTeamScoreByInning
    # awayTeamHomeRunByInning
    # homeTeamHomeRunByInning
    awayTeamOutsByInning
    homeTeamOutsByInning
    # awayTeamGrandSlamsByInning
    # homeTeamGrandSlamsByInning
    awayTeamCurrentBases
    homeTeamCurrentBases
  }
`;

export type BaseballLiveDataTypeFragment = {
  gameId: number;
  additionalData: string;
  period: string;
  isHomeTeamBatting: boolean;
  currentStrikes: number;
  currentBalls: number;
  awayTeamScoreByInning: number[];
  homeTeamScoreByInning: number[];
  // awayTeamHomeRunByInning: number[]
  // homeTeamHomeRunByInning: number[]
  awayTeamOutsByInning: number[];
  homeTeamOutsByInning: number[];
  // awayTeamGrandSlamsByInning: number[]
  // homeTeamGrandSlamsByInning: number[]
  awayTeamCurrentBases: boolean[];
  homeTeamCurrentBases: boolean[];
};
