import useGetPathKey from "@/hooks/useGetPathKey";
import React from "react";
import { Button, Link, styled, Typography, useTheme } from "@mui/material";
import { useUser } from "@/user/UserContext";
import UserIcon from "../Icons/UserIcon";
import { KeyOfPages } from "../Common/Filters/commonFilterType";

const StyledTypography = styled(Typography, {
  shouldForwardProp: (propName) => propName !== "isActive",
})<{ isActive?: boolean }>(
  ({ theme, isActive }) => `
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid ${isActive ? theme.palette.secondary.contrastText : theme.palette.border.secondary};
    color: ${isActive ? theme.palette.secondary.contrastText : undefined};
    display: flex;
    justify-content: center;
    align-items: center;
`,
);

function HeaderUserBtn() {
  const user = useUser();
  const theme = useTheme();
  const pathKey = useGetPathKey<KeyOfPages | "user-control-panel">();
  const isUserControlPanel = pathKey === "user-control-panel";
  return (
    <Link href="/user-control-panel">
      <Button
        sx={{
          minWidth: 46,
          height: 46,
          bgcolor: isUserControlPanel ? theme.palette.secondary.main : undefined,
          color: isUserControlPanel ? theme.palette.secondary.contrastText : theme.palette.text.primary,
          "&:hover": {
            color: isUserControlPanel ? theme.palette.text.primary : undefined,
          },
        }}
      >
        {user.isLoggedIn ? (
          <StyledTypography isActive={isUserControlPanel}>{user.data!.emailAddress.slice(0, 1).toUpperCase()}</StyledTypography>
        ) : (
          <UserIcon sx={{ fontSize: 32 }} />
        )}
      </Button>
    </Link>
  );
}

export default HeaderUserBtn;
