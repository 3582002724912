export const SOCCER_LIVE_DATA_TYPE_FRAGMENT = `
  fragment SoccerLiveDataTypeFragment on SoccerLiveDataType {
    gameId
    additionalData
    period
    gameTimeSeconds
    homeTeamHasPossession
    awayTeamScoreByHalf
    homeTeamScoreByHalf
    # awayTeamCornersByHalf
    # homeTeamCornersByHalf
    # awayTeamFoulsByHalf
    # homeTeamFoulsByHalf
    # awayTeamFreeKicksByHalf
    # homeTeamFreeKicksByHalf
    # awayTeamGoalKicksByHalf
    # homeTeamGoalKicksByHalf
    # awayTeamOffsidesByHalf
    # homeTeamOffsidesByHalf
    # awayTeamPenaltiesByHalf
    # homeTeamPenaltiesByHalf
    # awayTeamRedCardsByHalf
    # homeTeamRedCardsByHalf
    # awayTeamSubsByHalf
    # homeTeamSubsByHalf
    # awayTeamThrowInsByHalf
    # homeTeamThrowInsByHalf
    # awayTeamYellowCardsByHalf
    # homeTeamYellowCardsByHalf
    # isClockRunning
  }
`;

export type SoccerLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  awayTeamScoreByHalf: number[];
  homeTeamScoreByHalf: number[];
  awayTeamCornersByHalf: number[];
  homeTeamCornersByHalf: number[];
  // awayTeamFoulsByHalf: number[],
  // homeTeamFoulsByHalf: number[],
  // awayTeamFreeKicksByHalf: number[],
  // homeTeamFreeKicksByHalf: number[],
  // awayTeamGoalKicksByHalf: number[],
  // homeTeamGoalKicksByHalf: number[],
  // awayTeamOffsidesByHalf: number[],
  // homeTeamOffsidesByHalf: number[],
  // awayTeamPenaltiesByHalf: number[],
  // homeTeamPenaltiesByHalf: number[],
  // awayTeamRedCardsByHalf: number[],
  // homeTeamRedCardsByHalf: number[],
  // awayTeamSubsByHalf: number[],
  // homeTeamSubsByHalf: number[],
  // awayTeamThrowInsByHalf: number[],
  // homeTeamThrowInsByHalf: number[],
  // awayTeamYellowCardsByHalf: number[],
  // homeTeamYellowCardsByHalf: number[],
  // isClockRunning: boolean,
};
