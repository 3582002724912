import React, { useEffect, useRef } from "react";
import { Box, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import DiscordBrandIcon from "@/assests/icons/DiscordBrandIcon";
import Link from "next/link";
import { karantina } from "@/utis/fonts";
import XIcon from "@mui/icons-material/X";
import gsap from "gsap";
import { useTranslations } from "next-intl";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.customBackground.footer,
}));

const StyledInnerWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(7)}`,
  maxWidth: "1312px",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    padding: `0 ${theme.spacing(2.5)}`,
  },
}));

const StyledTop = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(12)} 0`,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(6)} 0`,
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const StyledLogos = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(6),
  },
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "80px",
  lineHeight: 1,
  textTransform: "uppercase",
  fontWeight: "bold",
  maxWidth: `${theme.spacing(80)}`,
  color: `${theme.palette.customBackground.custom}`,
  fontFamily: `${karantina.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    fontSize: "48px",
  },
}));

const StyledBottom = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} 0`,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  "& p": {
    width: "50%",
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.customBackground.custom,
    margin: 0,
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    flexDirection: "column",
    alignItems: "stretch",
    rowGap: theme.spacing(3),
    "& p": {
      width: "100%",
    },
  },
}));

const LogogWrapper = styled("a")(({ theme }) => ({
  background: theme.palette.text.primary,
  borderRadius: "12px",
  padding: theme.spacing(2),
  display: "flex",
  color: theme.palette.customBackground.cards,
}));

const GradientBox = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  background: `linear-gradient(to right, transparent, ${theme.palette.customBackground.custom}, transparent)`, // Your gradient colors
}));

const LinkWrapper = styled(Link)(({ theme }) => ({
  color: theme.palette.customBackground.custom,
  fontWeight: "bold",
}));

function LineBreak() {
  return <br />;
}

function WordSpan(chunks: React.ReactNode) {
  return <span className="footer-word">{chunks}</span>;
}

function WordSpan1(chunks: React.ReactNode) {
  if (Array.isArray(chunks) && (chunks[0] === "Privacy Policy" || chunks[0] === "Politique de confidentialité")) {
    return (
      <LinkWrapper className="footer-word" href="/privacy-policy">
        {chunks}
      </LinkWrapper>
    );
  }
  if (Array.isArray(chunks) && chunks[0] === "Terms of Service") {
    return (
      <LinkWrapper className="footer-word" href="/terms-of-service">
        {chunks}
      </LinkWrapper>
    );
  }
}

function Footer() {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const t = useTranslations("Home");

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const triggerElem = triggerRef.current;

    const words = gsap.utils.toArray("span.footer-word");

    if (triggerElem) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: triggerElem,
            start: "top bottom",
            end: "+=10%",
            scrub: 0,
          },
        })
        .set(
          words,
          {
            color: theme.palette.text.primary,
            stagger: 1,
          },
          0.1,
        );
    }
  }, [theme]);

  return (
    <StyledWrapper>
      <StyledInnerWrapper ref={triggerRef}>
        <StyledTop>
          <StyledText>{t.rich("footer_title", { br: LineBreak, span: WordSpan })}</StyledText>
          <StyledLogos display="flex" gap={2} flexDirection={isMobile ? "row" : "column"} ml="auto">
            <LogogWrapper href="https://discord.gg/9dsyWsbnRx" target="_blank" rel="noreferrer noopener">
              <DiscordBrandIcon fontSize="large" />
            </LogogWrapper>
            <LogogWrapper href="https://x.com/PickTheOdd" target="_blank" rel="noreferrer noopener">
              <XIcon sx={{ width: "35px", height: "35px" }} />
            </LogogWrapper>
          </StyledLogos>
        </StyledTop>
        <GradientBox />
        <StyledBottom>
          <Typography>{t.rich("tos", { br: LineBreak, span: WordSpan1 })}</Typography>
          <Typography>{t("footer_desc")}</Typography>
        </StyledBottom>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
}

export default Footer;
