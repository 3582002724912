import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { Link, Stack, styled } from "@mui/material";
import LogoName from "../Icons/LogoName";
import ServerStatus from "../ServerStatus/ServerStatus";
import ServerStatusHeaderMessage from "../ServerStatus/ServerStatusHeaderMessage";
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from "./common";
import HeaderUserBtn from "./HeaderUserBtn";

const StyledAppBar = styled(AppBar)(
  ({ theme }) => `
    position: static;
    background-image: none;
    box-shadow: none;
    z-index: 10;
    height: ${HEADER_HEIGHT}px;
    ${theme.breakpoints.down("md")} {
    height: ${HEADER_HEIGHT_MOBILE}px;
  }
`,
);

type HeaderProps = {
  endComponent?: React.ReactNode;
};

function Header({ endComponent }: HeaderProps) {
  const userIconButton = (
    <Stack direction="row" sx={{ flexGrow: 0 }}>
      <Box mr={2} sx={{ display: { md: "flex", xs: "none" } }}>
        <ServerStatus />
      </Box>
      <HeaderUserBtn />
    </Stack>
  );

  return (
    <StyledAppBar enableColorOnDark id="app-header">
      <Container maxWidth={false} sx={{ flexGrow: 1 }}>
        <Toolbar disableGutters sx={{ height: "100%" }}>
          <Box sx={{ display: { md: "flex", xs: "none" } }}>
            <Link href="/" style={{ display: "flex", alignItems: "center" }}>
              <LogoName />
            </Link>
          </Box>
          <Box sx={{ display: { md: "none", xs: "flex" } }}>
            <Link href="/" style={{ display: "flex", alignItems: "center" }}>
              <LogoName width={80} height={44} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              display: { md: "flex", xs: "none" },
            }}
          >
            <ServerStatusHeaderMessage />
            {userIconButton}
          </Box>

          {/* Mobile Header */}
          <Box
            sx={{
              display: { md: "none", xs: "flex" },
              justifyContent: "flex-end",
            }}
            gap={1}
          >
            <ServerStatus />
            {userIconButton}
            {endComponent}
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

export default Header;
