export enum UpcomingGameStatus {
  ALL = "ALL",
  NONE = "NONE",
}

export enum LiveGameStatus {
  ALL = "ALL",
  NONE = "NONE",
  TIMEOUT = "TIMEOUT",
}
