import { KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { DEFAULT_FILTER_VALUE } from "@/user/user-settings/pageFilterHelper";

type InternalKeyOfPages = KeyOfPages;

const useGetFilterNavigation = (pathKey?: InternalKeyOfPages) => {
  const allPageFilters = useUserSettingsContextSelector((ctx) => ctx.allPageFilters);

  if (!pathKey) {
    return undefined;
  }
  if (pathKey in allPageFilters) {
    return allPageFilters[pathKey];
  }
  return DEFAULT_FILTER_VALUE;
};

export default useGetFilterNavigation;
