import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { webSocket } from "@/apollo/factories";

function WebSocketStatus() {
  const [wsStatus, setWsStatus] = useState<string>("connected");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    let timeout: number | undefined;
    let activeSocket: WebSocket;

    const handleOpen = () => {
      setWsStatus("connected");
      if (timeout) {
        clearTimeout(timeout);
      }
      setIsModalOpen(false);
    };

    const handleDisconnect = () => {
      // eslint-disable-next-line no-console
      console.log("WebSocket disconnected or error occurred", wsStatus);
      timeout = window.setTimeout(() => {
        setWsStatus("disconnected");
        setIsModalOpen(true);
      }, 3000); // Wait for 3 seconds before showing the modal
    };

    const connect = () => {
      activeSocket = webSocket.wsClient as unknown as WebSocket; // Directly cast to WebSocket for event handling
      if (activeSocket) {
        // I don't think this works
        activeSocket.onopen = handleOpen;
        activeSocket.onclose = handleDisconnect;
        activeSocket.onerror = handleDisconnect;

        activeSocket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.type === "connection_ack") {
            handleOpen();
          } else if (message.type === "error") {
            handleDisconnect();
          }
        };
      }
    };

    connect();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (activeSocket) {
        activeSocket.onopen = null;
        activeSocket.onclose = null;
        activeSocket.onerror = null;
        activeSocket.onmessage = null;
        activeSocket.close?.();
      }
    };
  }, [wsStatus]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">WebSocket Disconnected</DialogTitle>
      <DialogContent>WebSocket connection lost. Please check your network.</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WebSocketStatus;
