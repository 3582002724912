import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function ConnectIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={9.882} viewBox="0 0 24 9.882" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23.922 4.196h-4.299A4.659 4.659 0 0 0 14.98 0.471h-5.849a4.659 4.659 0 0 0 -4.627 3.726H0.078v1.489h4.419a4.635 4.635 0 0 0 4.634 3.726h5.849a4.635 4.635 0 0 0 4.635 -3.725h4.306zm-12.607 3.725h-2.183A3.106 3.106 0 0 1 5.92 4.941a3.106 3.106 0 0 1 3.212 -2.98h2.19zm3.666 0h-2.235V1.961h2.235A3.106 3.106 0 0 1 18.192 4.941a3.106 3.106 0 0 1 -3.212 2.98" />
    </SvgIcon>
  );
}

export default ConnectIcon;
