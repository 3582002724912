export const ICE_HOCKEY_LIVE_DATA_TYPE_FRAGMENT = `
  fragment IceHockeyLiveDataTypeFragment on IceHockeyLiveDataType {
    gameId
    additionalData
    period
    gameTimeSeconds
    # homeTeamHasPossession
  }
`;

export type IceHockeyLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  // homeTeamHasPossession: boolean,
};
