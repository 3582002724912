"use client";

import useGetConstants from "@/hooks/useGetConstants";
import useGetServerStatus from "@/hooks/useGetServerStatus";

function AppSubscriptions() {
  useGetServerStatus();
  useGetConstants();
  return null;
}

export default AppSubscriptions;
