import { useAppDispatch } from "@/store/store";
import {
  ServerStatusEnum,
  ServerStatusType,
  initialState as serverStatusInitialState,
  toolsSportCountsAction,
} from "@/store/toolsSportCounts";
import { gql, useSubscription } from "@apollo/client";

export const GET_SERVER_STATUS = gql`
  subscription GetServerStatus {
    serverStatus {
      serverStatusId
      arbitrageCountByBetSite
      middleCountByBetSite
      freeBetCountByBetSite
      lowHoldCountByBetSite
      expectedValueCountByBetSite
      arbitrageCountByLeague
      middleCountByLeague
      freeBetCountByLeague
      lowHoldCountByLeague
      expectedValueCountByLeague
      gameCountByLeagueEnumIdx
    }
  }
`;

export type GetServerStatus = {
  serverStatus: ServerStatusType;
};

export default function useGetServerStatus() {
  const dispatch = useAppDispatch();
  useSubscription<GetServerStatus>(GET_SERVER_STATUS, {
    onData: ({ data: subData }) => {
      const { data } = subData;
      if (!data) return;
      dispatch(toolsSportCountsAction.setToolsSportCounts(data.serverStatus));
    },
    onError: () => {
      dispatch(
        toolsSportCountsAction.setToolsSportCounts({
          ...serverStatusInitialState,
          serverStatusId: ServerStatusEnum.OFFLINE,
        }),
      );
    },
  });
  return null;
}
