"use client";

import React, { ComponentProps } from "react";
import Image from "next/image";
import { useTheme } from "@mui/material/styles";

type LogoNameProps = Omit<ComponentProps<typeof Image>, "src" | "alt">;
function LogoName({ ...restProps }: LogoNameProps) {
  const theme = useTheme();
  const logoSrc = theme.palette.mode === "dark" ? "/images/darkbanner.png" : "/images/lightbanner.png";

  return <Image src={logoSrc} alt="Pick The Odds" width={102} height={53} priority {...restProps} />;
}

export default LogoName;
