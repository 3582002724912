"use client";

import { Global, css } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";

const global = css`
  html,
  body {
    min-height: 100vh;
  }

  body {
    min-width: 360px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  // logo of Trading view chart
  #tv-attr-logo {
    display: none;
  }

  #__next {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

export default function AppCSS() {
  return (
    <>
      <CssBaseline />
      <Global styles={global} />
    </>
  );
}
