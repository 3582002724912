function registerServiceWorker() {
  return navigator.serviceWorker
    .register("/sw.js")
    .then((registration) => registration)
    .catch((err) => null);
}

let serviceWorkerRegistration: ServiceWorkerRegistration | null;

export function getServiceWorker() {
  if (serviceWorkerRegistration && "showNotification" in serviceWorkerRegistration) {
    return serviceWorkerRegistration;
  }
  return null;
}

function WebNotification() {
  if (typeof navigator !== "undefined" && "serviceWorker" in navigator) {
    registerServiceWorker().then((data) => {
      serviceWorkerRegistration = data;
    });
  }

  return null;
}

export default WebNotification;
