export const FOOTBALL_LIVE_DATA_TYPE_FRAGMENT = `
  fragment FootballLiveDataTypeFragment on FootballLiveDataType {
  gameId
  additionalData
  period
  gameTimeSeconds
  homeTeamHasPossession
  activeDown
  yardsToNextDown
  yardsDistance
  awayTeamDrives
  homeTeamDrives
  awayTeamPasses
  homeTeamPasses
  awayTeamTurnovers
  homeTeamTurnovers
  awayTeamPenalties
  homeTeamPenalties
  awayTeamOnePointConversions
  homeTeamOnePointConversions
  awayTeamTwoPointConversions
  homeTeamTwoPointConversions
  awayTeamSafeties
  homeTeamSafeties
  awayTeamFieldGoals
  homeTeamFieldGoals
  awayTeamCoachChallenges
  homeTeamCoachChallenges
  awayTeamTotalPoints
  homeTeamTotalPoints
  awayTeamTouchdowns
  homeTeamTouchdowns
  awayTeamTimeouts
  homeTeamTimeouts
  }
`;

export type FootballLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  activeDown: string;
  yardsToNextDown: number[];
  yardsDistance: number[];
  awayTeamDrives: number[];
  homeTeamDrives: number[];
  awayTeamPasses: number[];
  homeTeamPasses: number[];
  awayTeamTurnovers: number[];
  homeTeamTurnovers: number[];
  awayTeamPenalties: number[];
  homeTeamPenalties: number[];
  awayTeamOnePointConversions: number[];
  homeTeamOnePointConversions: number[];
  awayTeamTwoPointConversions: number[];
  homeTeamTwoPointConversions: number[];
  awayTeamSafeties: number[];
  homeTeamSafeties: number[];
  awayTeamFieldGoals: number[];
  homeTeamFieldGoals: number[];
  awayTeamCoachChallenges: number[];
  homeTeamCoachChallenges: number[];
  awayTeamTotalPoints: number[];
  homeTeamTotalPoints: number[];
  awayTeamTouchdowns: number[];
  homeTeamTouchdowns: number[];
  awayTeamTimeouts: number[];
  homeTeamTimeouts: number[];
};
