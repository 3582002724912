import React, { useCallback, useMemo, useState } from "react";

export type PauseDataContextValue = {
  isDatapaused: boolean;
  togglePauseData: () => void;
};

const PauseDataContext = React.createContext<PauseDataContextValue>(undefined!);
export default PauseDataContext;

export type PauseDataProviderProps = {
  children: React.ReactNode;
};

export function PauseDataProvider(props: PauseDataProviderProps) {
  const { children } = props;
  const [isDatapaused, setIsDatapaused] = useState(false);

  const togglePauseData = useCallback(() => {
    setIsDatapaused((prev) => !prev);
  }, []);

  const providerValue: PauseDataContextValue = useMemo(
    () => ({
      isDatapaused,
      togglePauseData,
    }),
    [isDatapaused, togglePauseData],
  );

  return <PauseDataContext.Provider value={providerValue}>{children}</PauseDataContext.Provider>;
}
