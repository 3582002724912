import React, { ComponentProps } from "react";
import SvgIcon from "@mui/material/SvgIcon";

function DiscordBrandIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props}>
      <path d="M20.317,3.987C18.787,3.25 17.147,2.708 15.432,2.397C15.401,2.391 15.37,2.406 15.353,2.436C15.142,2.83 14.909,3.343 14.745,3.747C12.9,3.457 11.065,3.457 9.258,3.747C9.095,3.334 8.852,2.83 8.641,2.436C8.624,2.407 8.593,2.392 8.562,2.397C6.848,2.707 5.208,3.249 3.677,3.987C3.664,3.993 3.652,4.003 3.645,4.015C0.533,8.891 -0.319,13.647 0.099,18.343C0.101,18.366 0.113,18.388 0.13,18.402C2.183,19.984 4.172,20.943 6.123,21.58C6.155,21.59 6.188,21.578 6.207,21.551C6.669,20.89 7.081,20.192 7.433,19.459C7.454,19.416 7.434,19.365 7.392,19.348C6.739,19.089 6.118,18.772 5.52,18.412C5.472,18.383 5.469,18.312 5.512,18.278C5.638,18.18 5.764,18.077 5.884,17.973C5.906,17.954 5.936,17.95 5.962,17.962C9.889,19.843 14.142,19.843 18.023,17.962C18.049,17.949 18.079,17.953 18.102,17.972C18.222,18.076 18.348,18.18 18.474,18.278C18.518,18.312 18.515,18.383 18.468,18.412C17.87,18.779 17.248,19.089 16.595,19.347C16.552,19.364 16.533,19.416 16.554,19.459C16.914,20.191 17.326,20.888 17.779,21.55C17.798,21.578 17.832,21.59 17.863,21.58C19.824,20.943 21.813,19.984 23.865,18.402C23.883,18.388 23.895,18.367 23.897,18.344C24.397,12.914 23.059,8.198 20.348,4.016C20.342,4.003 20.33,3.993 20.317,3.987ZM8.02,15.483C6.838,15.483 5.863,14.345 5.863,12.946C5.863,11.548 6.819,10.409 8.02,10.409C9.231,10.409 10.196,11.558 10.177,12.946C10.177,14.345 9.221,15.483 8.02,15.483ZM15.995,15.483C14.812,15.483 13.838,14.345 13.838,12.946C13.838,11.548 14.793,10.409 15.995,10.409C17.206,10.409 18.171,11.558 18.152,12.946C18.152,14.345 17.206,15.483 15.995,15.483Z" />
    </SvgIcon>
  );
}

export default DiscordBrandIcon;
