// from https://github.com/apollographql/apollo-client-nextjs?tab=readme-ov-file#in-ssr

"use client";

import { ApolloLink, HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";
import React, { useCallback } from "react";
import { RefreshAnonymousTokenParams, useUser } from "@/user/UserContext";
import * as Sentry from "@sentry/browser";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { gcGetApiToken } from "@/api/api";
import { createSplitWsOrHttpLink, createErrorLink, createHttpAuthLink, createWsLink, cAwaitApiTokenLink } from "./factories";

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPH_QL_ENDPOINT,
  fetchOptions: { cache: "no-store" },
});

const batchLink = new BatchHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPH_QL_ENDPOINT,
  batchMax: 10, // Maximum number of operations to include in one batch
  batchInterval: 20, // Wait time in milliseconds before batching
});

function makeClient(
  refreshUserAnonymousToken: (params?: RefreshAnonymousTokenParams) => Promise<string | null>,
  userToken?: string,
) {
  let link: ApolloLink;
  if (typeof document === "undefined") {
    link = ApolloLink.from([
      new SSRMultipartLink({
        stripDefer: true,
      }),
      createHttpAuthLink({ getApiToken: userToken ? () => userToken : gcGetApiToken }),
      batchLink.concat(httpLink),
    ]);
  } else {
    const errorLink = createErrorLink(refreshUserAnonymousToken);
    const authLink = createHttpAuthLink({ getApiToken: gcGetApiToken });
    link = createSplitWsOrHttpLink({
      wsLink: ApolloLink.from([cAwaitApiTokenLink, createWsLink(gcGetApiToken, refreshUserAnonymousToken)]),
      httpLink: ApolloLink.from([cAwaitApiTokenLink, authLink, errorLink, batchLink.concat(httpLink)]),
    });
  }

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link,
  });
}

type ApolloWrapperhType = {
  children: React.ReactNode;
  userToken?: string;
};

export function ApolloWrapper({ children, userToken }: ApolloWrapperhType) {
  const user = useUser();

  const makeClientCallback = useCallback(() => {
    try {
      return makeClient(user.refreshUserToken, userToken);
    } catch (err) {
      Sentry.addBreadcrumb({
        category: "error",
        message: `Websocket Authentication Failed`,
        data: Array.isArray(err) ? err?.[0] : err,
        level: "log",
      });
      throw err;
    }
  }, [user.refreshUserToken, userToken]);

  return <ApolloNextAppProvider makeClient={makeClientCallback}>{children}</ApolloNextAppProvider>;
}
